import { ApolloProvider } from '@apollo/client';
import { createTheme, ThemeProvider } from '@mui/material';
import { useMemo, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import router from '../config/router';
import { createWebtuneTheme } from '../config/theming.ts';
import { getApolloClient } from '../graphql';
import useLocalStorage from '../hooks/useLocalStorage';
import TawktoChatProvider from './providers/TawktoChatProvider.tsx';
import { UiThemeContext } from './UiThemeContext';
import '../styles/main.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-photo-view/dist/react-photo-view.css';

const App = () => {
  const { get } = useLocalStorage();
  const [ localTheme, setLocalTheme ] = useState(createWebtuneTheme());

  const context = useMemo(() => ({
    theme: localTheme,
    setTheme: (mode: 'light' | 'dark') => setLocalTheme(createTheme({
      palette: {
        mode,
      },
    })),
  }), [ localTheme ]);

  return (
    // @ts-ignore
    <ApolloProvider client={getApolloClient(get(import.meta.env.VITE_TOKEN_KEYNAME, ''))}>
      <UiThemeContext.Provider value={context}>
        <ThemeProvider theme={context.theme}>
          <TawktoChatProvider>
            <RouterProvider router={router} />
          </TawktoChatProvider>
        </ThemeProvider>
      </UiThemeContext.Provider>

      <ToastContainer />
    </ApolloProvider>
  );
};

export default App;

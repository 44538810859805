import { useReactiveVar } from '@apollo/client';
import {
  ReactNode, useEffect, useMemo, useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { isAppInitializedVar } from '../../graphql';
import useReactiveUserConnected from '../../hooks/data/useReactiveUserConnected.ts';
import ForgottenPasswordDialog from '../pages/partials/dialogs/forgotten-password/ForgottenPasswordDialog.tsx';
import LoginDialog from '../pages/partials/dialogs/login/LoginDialog.tsx';
import RegisterDialog from '../pages/partials/dialogs/register/RegisterDialog.tsx';
import ValidateDialog from '../pages/partials/dialogs/validate-account/ValidateDialog.tsx';
import { OfflineDialogsContext, OfflineDialogsContextProps } from './OfflineDialogsContext.ts';

interface Props {
  children: ReactNode,
}

const OfflineDialogsProvider = ({ children }: Props) => {
  const { pathname } = useLocation();
  const { isConnected } = useReactiveUserConnected();
  const [ isLoginDialogOpen, setIsLoginDialogOpen ] = useState(false);
  const [ isForgottenPasswordDialogOpen, setIsForgottenPasswordDialogOpen ] = useState(false);
  const [ isRegisterDialogOpen, setIsRegisterDialogOpen ] = useState(false);
  const [ isValidateAccountDialogOpen, setIsValidateAccountDialogOpen ] = useState(false);
  const isAppInitialized = useReactiveVar(isAppInitializedVar);

  const context: OfflineDialogsContextProps = useMemo(() => ({
    openLoginDialog: () => setIsLoginDialogOpen(true),
    openRegisterDialog: () => setIsRegisterDialogOpen(true),
    openForgottenPasswordDialog: () => setIsForgottenPasswordDialogOpen(true),
    openValidateAccountDialog: () => setIsValidateAccountDialogOpen(true),
  }), []);

  useEffect(() => {
    if (isAppInitialized && !isConnected && pathname === '/login') {
      setIsLoginDialogOpen(true);
    }

    if (isAppInitialized && !isConnected && pathname === '/register') {
      setIsRegisterDialogOpen(true);
    }

    if (isAppInitialized && !isConnected && pathname.includes('/validate-account')) {
      setIsValidateAccountDialogOpen(true);
    }
  }, [ pathname, isConnected, isAppInitialized ]);

  return (
    <OfflineDialogsContext.Provider value={context}>
      { children }

      { isLoginDialogOpen && <LoginDialog onClose={() => setIsLoginDialogOpen(false)} /> }
      { isForgottenPasswordDialogOpen && <ForgottenPasswordDialog onClose={() => setIsForgottenPasswordDialogOpen(false)} /> }
      { isRegisterDialogOpen && <RegisterDialog onClose={() => setIsRegisterDialogOpen(false)} /> }
      { isValidateAccountDialogOpen && <ValidateDialog onClose={() => setIsValidateAccountDialogOpen(false)} /> }
    </OfflineDialogsContext.Provider>
  );
};

export default OfflineDialogsProvider;

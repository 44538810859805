import { Dialog } from '@mui/material';
import VStack from '../../../../ui-kit/layout/VStack.tsx';
import RegisterForm from './RegisterForm.tsx';

interface Props {
  onClose: () => void,
}

const LoginDialog = ({
  onClose,
}: Props) => (
  <Dialog
    id="register-dialog"
    onClose={onClose}
    open
    scroll="body"
      // @ts-ignore
    maxWidth="md"
    disableEscapeKeyDown
  >
    <VStack width={728} p={5}>
      <RegisterForm
        onCancel={onClose}
        onLoggedIn={onClose}
      />
    </VStack>
  </Dialog>
);

export default LoginDialog;

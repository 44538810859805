import {
  Alert, Button, ButtonGroup, Typography,
} from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dealer,
  RegisterDto,
  SaveDealerAddressDto,
  SaveDealerDto,
  SaveUserDto,
  User,
  useRegisterMutation,
  UserSubscriptionType,
  useSaveDealerAddressMutation,
  useSaveDealerMutation,
  useSaveUserMutation,
} from '../../../../../graphql';
import useReactiveUserConnected from '../../../../../hooks/data/useReactiveUserConnected.ts';
import useValidateRegisterDto from '../../../../../hooks/data/validations/useValidateRegisterDto.ts';
import useValidateSaveDealerAddressDto from '../../../../../hooks/data/validations/useValidateSaveDealerAddressDto.ts';
import useValidateSaveDealerDto from '../../../../../hooks/data/validations/useValidateSaveDealerDto.ts';
import useValidateSaveUserDto from '../../../../../hooks/data/validations/useValidateSaveUserDto.ts';
import useLogin from '../../../../../hooks/useLogin.tsx';
import useUiToast from '../../../../../hooks/useUiToast.ts';
import HStack from '../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../ui-kit/layout/VStack.tsx';
import RegisterFormCreateProfile from './RegisterFormCreateProfile.tsx';
import RegisterFormDealer from './RegisterFormDealer.tsx';
import RegisterFormDealerAddress from './RegisterFormDealerAddress.tsx';
import RegisterFormUpdateProfile from './RegisterFormUpdateProfile.tsx';

const steps = (t: any) => [
  t('offline.registration.steps.my-subscription.title'),
  t('offline.registration.steps.my-account.title'),
  t('offline.registration.steps.my-company.title'),
];

interface Props {
  onCancel: () => void,
  onLoggedIn: () => void,
}

const RegisterForm = ({ onCancel, onLoggedIn }: Props) => {
  const { t } = useTranslation();
  const { refetchMe } = useReactiveUserConnected();
  const [ selectedSubscriptions, setSelectedSubscriptions ] = useState<UserSubscriptionType[]>([ UserSubscriptionType.Client, UserSubscriptionType.Seller ]);
  const [ step, setStep ] = useState(0);
  const [ subStep, setSubStep ] = useState(0);
  const [ createdUser, setCreatedUser ] = useState<User|null>(null);
  const [ registerDto, setRegisterDto ] = useState<RegisterDto|null>(null);
  const [ saveUserDto, setSaveUserDto ] = useState<SaveUserDto|null>(null);
  const [ createdDealer, setCreatedDealer ] = useState<Dealer|null>(null);
  const [ saveDealerDto, setSaveDealerDto ] = useState<SaveDealerDto|null>(null);
  const [ saveDealerAddressDto, setSaveDealerAddressDto ] = useState<SaveDealerAddressDto|null>(null);
  const [ isPushing, setIsPushing ] = useState(false);
  const { login, fullLogin } = useLogin();
  const [ register ] = useRegisterMutation();
  const [ saveUser ] = useSaveUserMutation();
  const [ saveDealer ] = useSaveDealerMutation();
  const [ saveDealerAddress ] = useSaveDealerAddressMutation();
  const { error } = useUiToast();
  const canRegister = useValidateRegisterDto(registerDto);
  const canSaveUser = useValidateSaveUserDto(saveUserDto);
  const canSaveDealer = useValidateSaveDealerDto(saveDealerDto);
  const canSaveDealerAddress = useValidateSaveDealerAddressDto(saveDealerAddressDto);
  const isShort = useMemo(() => document.location.search.includes('short'), []);

  const handleLogin = async () => {
    await fullLogin(registerDto!.email, registerDto!.password!);

    onLoggedIn();
  };

  const handleSaveUser = async () => {
    if (!canRegister || (createdUser && !canSaveUser)) {
      return;
    }

    if (canSaveUser) {
      await refetchMe();

      setStep(2);

      const { data } = await saveUser({ variables: { dto: saveUserDto! } });

      if (!data) {
        error(t('errors.error-while-updating-account'));

        setIsPushing(false);

        return;
      }

      setCreatedUser(data.saveUser as User);

      setIsPushing(false);

      return;
    }

    setIsPushing(true);

    const { data } = await register({ variables: { dto: { ...registerDto!, selectedSubscriptions } } });

    if (!data) {
      error(t('errors.error-while-creating-account'));

      setIsPushing(false);

      return;
    }

    setStep(2);

    setCreatedUser(data.register as User);
    setSaveUserDto(data.register as SaveUserDto);

    await login(registerDto!.email, registerDto!.password!);

    setIsPushing(false);
  };

  const handleSaveDealer = async () => {
    if (!canSaveDealer && !isShort) {
      return;
    }

    setIsPushing(true);

    const { data } = await saveDealer({
      variables: {
        dto: {
          ...saveDealerDto!,
          ...(createdDealer ? { id: createdDealer.id } : {}),
          shorten: isShort,
        },
      },
    });

    if (!data) {
      error(t('errors.error-while-configuring-company'));

      return;
    }

    setSubStep(1);

    setCreatedDealer(data.saveDealer as Dealer);

    if (isShort) {
      handleLogin();

      return;
    }

    setIsPushing(false);
  };

  const handleSaveDealerAddress = async () => {
    if (!canSaveDealerAddress) {
      return;
    }

    setIsPushing(true);

    const { data } = await saveDealerAddress({ variables: { dto: saveDealerAddressDto! } });

    if (!data) {
      error(t('errors.error-while-adding-headquarter-address'));

      return;
    }

    setStep(3);

    setIsPushing(false);
  };

  useEffect(() => {
    if (isShort) {
      setSelectedSubscriptions([ UserSubscriptionType.Seller, UserSubscriptionType.Client ]);
      setStep(1);
    }
  }, [ isShort ]);

  return (
    <VStack width="100%" alignItems="center" py={2}>
      <Stepper sx={{ width: '100%' }} id="stepper" activeStep={step} alternativeLabel>
        {(!isShort ? steps(t) : [ steps(t)[1], steps(t)[2] ]).map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <HStack justifyContent="center" mt={4} mb={0}>
        <Typography variant="h3" fontWeight="bold" textAlign="center">
          {/* {step === 0 && t('offline.registration.titles.select-subscription')} */}
          {step === 0 && t('offline.registration.titles.free-for-30-days') }
          {step === 1 && !createdUser && t('offline.registration.titles.create-your-account')}
          {step === 1 && createdUser && t('offline.registration.titles.update-your-profile')}
          {step === 2 && subStep === 0 && !createdDealer && t('offline.registration.titles.configure-your-company')}
          {step === 2 && subStep === 0 && createdDealer && t('offline.registration.titles.update-your-company')}
          {step === 2 && createdDealer && subStep === 1 && t('offline.registration.titles.almost-done')}
        </Typography>
      </HStack>

      {/* { */}
      {/*   step === 0 && ( */}
      {/*     <RegisterFormSubscriptions */}
      {/*       selectedSubscriptions={selectedSubscriptions} */}
      {/*       setSelectedSubscriptions={(subscriptions) => { */}
      {/*         if (!createdUser) { */}
      {/*           setSelectedSubscriptions(subscriptions); */}
      {/*         } */}
      {/*       }} */}
      {/*     /> */}
      {/*   ) */}
      {/* } */}

      {
        step === 0 && (
          <VStack mt={2}>
            <Typography dangerouslySetInnerHTML={{ __html: t('offline.registration.trial.p1') }} mt={2} />
            <Typography dangerouslySetInnerHTML={{ __html: t('offline.registration.trial.p2') }} mt={2} />
            <Typography dangerouslySetInnerHTML={{ __html: t('offline.registration.trial.p3') }} mt={2} />
            <Typography dangerouslySetInnerHTML={{ __html: t('offline.registration.trial.p4') }} mt={2} />
            <Typography dangerouslySetInnerHTML={{ __html: t('offline.registration.trial.p5') }} mt={2} />
          </VStack>
        )
      }

      {
        step === 1 && !createdUser && (
          <RegisterFormCreateProfile onChange={(dto) => setRegisterDto(dto)} />
        )
      }

      {
        step === 1 && createdUser && (
          <RegisterFormUpdateProfile user={createdUser} onChange={(dto) => setSaveUserDto(dto)} />
        )
      }

      {
        step === 2 && subStep === 0 && createdUser && (
          <RegisterFormDealer
            user={createdUser}
            dealer={(createdDealer as Dealer | null) ?? undefined}
            isShort={isShort}
            onChange={(dto) => setSaveDealerDto(dto)}
          />
        )
      }

      {
        step === 2 && subStep === 1 && createdDealer && (
          <RegisterFormDealerAddress onChange={(dto) => setSaveDealerAddressDto(dto)} />
        )
      }

      {
        step === 3 && (
          <VStack alignItems="center" mt={4} mb={0}>
            <Alert
              className="alert-success"
              severity="success"
              sx={{
                width: '100%',
                maxWidth: 600,
                mb: 5,
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: t('offline.registration.steps.done.success-alert') }} />
            </Alert>
            <Button variant="contained" onClick={handleLogin}>{t('buttons.login')}</Button>
          </VStack>
        )
      }

      <HStack justifyContent="flex-end" mt={5} gap={2} px={5}>
        <ButtonGroup>
          {
            step < 3 && ((step === 1 && !createdUser && !isShort) || step > 1) && (
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() => {
                  if (!subStep) {
                    setStep(step - 1);
                  }

                  if (subStep) {
                    setSubStep(subStep - 1);
                  }
                }}
              >
                {t('buttons.back')}
              </Button>
            )
          }

          {
            step === 0 && (
              <HStack alignItems="center" gap={1} flexWrap="wrap" justifyContent="center">
                <Button
                  size="medium"
                  variant="outlined"
                  color="secondary"
                  disabled={!isPushing && selectedSubscriptions.length === 0}
                  onClick={() => setStep(1)}
                >
                  { t('buttons.lets-go') }
                  {' '}
                  🚀
                </Button>

                <Button
                  size="medium"
                  variant="text"
                  onClick={() => onCancel()}
                >
                  {t('buttons.later')}
                </Button>
              </HStack>
            )
          }

          {
            step === 1 && !createdUser && (
              <Button size="small" variant="outlined" color="secondary" disabled={!canRegister} onClick={handleSaveUser}>
                {t('buttons.create-account')}
              </Button>
            )
          }

          {
            step === 1 && createdUser && (
              <Button size="small" variant="outlined" color="secondary" disabled={!canSaveUser} onClick={handleSaveUser}>
                {t('buttons.save')}
              </Button>
            )
          }

          {
            step === 2 && subStep === 0 && (
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                disabled={(!isShort && !canSaveDealer) || saveDealerDto?.name.length === 0}
                onClick={handleSaveDealer}
              >
                {!createdDealer ? t('buttons.configure-my-company') : t('buttons.save')}
              </Button>
            )
          }

          {
            step === 2 && subStep === 1 && createdDealer && (
              <>
                <Button
                  size="small"
                  variant="outlined"
                  color="secondary"
                  disabled={!canSaveDealerAddress}
                  onClick={handleSaveDealerAddress}
                >
                  {t('buttons.add-my-headquarter-address')}
                </Button>
              </>
            )
          }
        </ButtonGroup>
      </HStack>
    </VStack>
  );
};

export default RegisterForm;
